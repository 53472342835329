import { Injectable } from '@angular/core';
//import { AngularFireDatabase  } from '@angular/fire/database';
//mport { AngularFireDatabase  } from 'angularfire2/database/database';
import { AngularFireDatabase,AngularFireList } from 'angularfire2/database';
import { AuthProvider } from '../providers/auth/auth';

@Injectable()
export class PartesService{
  constructor(public afDB: AngularFireDatabase,public auth : AuthProvider){
    this.usuario =  this.getUsuario();
    /*this.getPersonal()
        .subscribe(personal => {
            this.personal = personal;
        });*/

        //this.partes$ = afDB.list('/encargados/' + this.usuario + '/partes/');    
  }

    usuario : string;
    //personal = [];
    //partes$: AngularFireList<any[]>

      //Añadir,editar y eliminar Partes    

      public editParte(parte){
        this.afDB.database.ref('/encargados/' + this.usuario + '/partes/' + parte.id).set(parte);
      } 

      public deleteParte(parte){
        this.afDB.database.ref('/encargados/' + this.usuario + '/partes/' + parte.id).remove();
      }
      
      /*//Devuelve el numero de Unidades de las lineas de un Parte
      public countUnidades(parte){
        let Unidades = parseInt("0");
        for (let i = 0; i < parte.lineas.length; i++){
          Unidades = Unidades + parseInt(parte.lineas[i].Unidades);
        }
        return Unidades;
      }
      */
      public getPartesOrdenado(){
        return this.afDB.list('/encargados/' + this.usuario + '/partes/',ref => ref.orderByChild('Orden'));
      }
      public getPartes(){
        return this.afDB.list('/encargados/' + this.usuario + '/partes/');
      }

      public getTareasParte(idParte){
        return this.afDB.list('/encargados/' + this.usuario + '/partes/' + idParte + '/tareas/');
      }

      /*public refreshPersonal(){
        this.getPersonal()
        .subscribe(personal => {
            this.personal = personal;
        });
      }*/
      /*public getPersonal(){
        return this.afDB.list('/personal/',{query:{orderByChild: 'Nombre'}});
      }*/
      public getPersonalCuadrilla(idCuadrilla){
        return this.afDB.list('/personal/' + idCuadrilla + '/',ref => ref.orderByChild('Nombre'));
        //return this.afDB.list('/personal/'+ idCuadrilla +'/',{query:{orderByChild: 'Nombre'}});
        //return this.afDB.list('/personal/'+ idCuadrilla +'/',{query:{orderByChild: 'Activo',equalTo : true }});
      }
         
      public getCuadrillas(){
        return this.afDB.list('/encargados/' + this.usuario + '/cuadrillas/',ref => ref.orderByChild('Nombre'));
        //return this.afDB.list('/encargados/' + this.usuario + '/cuadrillas/',{query:{orderByChild: 'Nombre'}});
      }  

      public getFincas(){
        //return this.afDB.list('/encargados/' + this.usuario + '/fincas/',{query:{orderByChild: 'Nombre'}});
      }  

      public getFincasEmpresa(idEmpresa){
        return this.afDB.list('/encargados/' + this.usuario + '/fincas/',ref => ref.orderByChild('idEmpresa').equalTo(idEmpresa));        
        //return this.afDB.list('/encargados/' + this.usuario + '/fincas/',{query:{orderByChild: 'idEmpresa',equalTo : idEmpresa}});  
      }      

      public getTareas(){
        return this.afDB.list('/tareas/',ref => ref.orderByChild('Nombre'));        
        //return this.afDB.list('/tareas/',{query:{orderByChild: 'Nombre'}});
      }  

      public getUsuario(){
        let strUsuario : string;
        strUsuario = this.auth.getUser();
        strUsuario = strUsuario.substring(0,2);
        return strUsuario;
      }

      //Devuelve el idParte siguiente.
      getIdParte(){
        let strUsuario : string;
        let usuario : number;
        let partesArray = [];
        let idParte = 0;

        strUsuario = this.auth.getUser();
        strUsuario = strUsuario.substring(1,2);
        usuario = parseInt(strUsuario);

        this.getPartes().valueChanges()
        .subscribe(partes => {
            partesArray = partes;
            if (partesArray.length > 0)
              idParte = partesArray[partesArray.length - 1].id;
            else
              idParte = 180000 + usuario * 1000;
              console.log('dentro getIdParte() ' + idParte);
        });

          
       // return idParte;    
      }

      
}