import { Injectable } from '@angular/core';
//import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireAuth} from '@angular/fire/auth/auth';
import * as firebase from 'firebase/app';
/*
  Generated class for the AuthProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class AuthProvider {

  constructor(private afAuth :  AngularFireAuth) {
    console.log('Hello AuthProvider Provider');
  }
 
  // Login de usuario
  loginUser(email:string, password:string){
    //console.log(email + ' ' + password);
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
    .then(user=>Promise.resolve(user))
    .catch(err=>Promise.reject(err))
  /*return this.firebaseAuthentication.signInWithEmailAndPassword(email, password)
    .then(user=>Promise.resolve(user))
    .catch(err=>Promise.reject(err))*/
  }

  // Devuelve la session
  get Session(){
    return this.afAuth.authState;
   }
  
   //Devuelve el user 
   getUser(){
    return this.afAuth.auth.currentUser.email;
   }

  // Logout de usuario
  logout(){
    this.afAuth.auth.signOut().then(()=>{
    //this.firebaseAuthentication.signOut().then(()=>{      
    // hemos salido
    })
  }

  loginUser2(user){
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(user.email, user.password)
      .then(
        res => resolve(res),
        err => reject(err))
    })
   }
  
   logoutUser(){
     return new Promise((resolve, reject) => {
       if(firebase.auth().currentUser){
         firebase.auth().signOut()
         .then(() => {
           console.log("LOG Out");
           resolve();
         }).catch((error) => {
           reject();
         });
       }
     })
   }

   userDetails(){
    return firebase.auth().currentUser;
  }

}
